import React from 'react';
import { string } from 'prop-types';
import { Flex } from '@chakra-ui/react';

import useDateCountdown from '@/hooks/useDateCountdown';

import CountItem from './CountItem';

import { txtDay, txtHour, txtMinutes, txtSeconds } from './locales';
import useLang from '@hooks/useLang';

function CountContainer() {
  const { days, hours, minutes, seconds } = useDateCountdown();
  const lang = useLang();
  return (
    <Flex alignItems="center">
      <CountItem text={txtDay[lang]} number={days} />
      <CountItem text={txtHour[lang]} number={hours} />
      <CountItem text={txtMinutes[lang]} number={minutes} />
      <CountItem text={txtSeconds[lang]} number={seconds} />
    </Flex>
  );
}

CountContainer.propTypes = {
  lang: string,
};

CountContainer.defaultProps = {
  lang: 'id',
};

export default React.memo(CountContainer);
