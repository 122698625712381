import getLocale from '@/helpers/getLocale';

export const txtTitle = {
  id: `Kehadiran Bapak/Ibu/Saudara/i akan menjadi kehormatan besar bagi kami dan keluarga.
  Mohon konfirmasi kehadiran Anda melalui formulir reservasi di bawah ini:`,
  en: `Your presence shall be a great honour for us and our families. Please confirm your attendance through reservation form below:`,
};

export const txtForm = {
  name: getLocale('Nama', 'Name'),
  hp: getLocale('Nomor HP', 'Phone Number'),
  address: getLocale('Alamat', 'Address'),
  willYoutAttend: getLocale('Apakah anda akan hadir?', 'Will you attend the wedding?'),
  willAttend: getLocale('Ya, saya akan hadir', 'Yes, I  will gladly attend'),
  noAttend: getLocale('Tidak, saya tidak bisa hadir', `No, I can't attend the wedding`),
  willYouBringPartner: getLocale('Berapa jumlah tamu yang akan hadir?', `How many guests will be attend?`),
  withPartner: getLocale('Ya, saya akan membawa 1 partner', `Yes, I will bring 1 partner here`),
  noPartner: getLocale('Tidak, saya akan hadir sendiri', `No, I will be by myself`),
  submit: getLocale('Kirim Konfirmasi >>', `Send Confirmation >>`),
  required: getLocale('Wajib diisi', 'This field required'),
  success: getLocale('Sukses!', 'Success!'),
  msgSuccess: getLocale(
    'Formulir reservasi kamu berhasil dikirim. Terima Kasih!',
    'Your response already submitted. Thank you!',
  ),
  msgError: getLocale(
    'Ada kesalahan ketika mengirimkan reservasi kamu. Silahkan coba beberapa saat lagi.',
    'Something wrong happened. Please try again later or refresh your browser!',
  ),
};
