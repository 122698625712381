import React, { useState } from 'react';
import { func, bool } from 'prop-types';

import { Box, Center, Heading } from '@chakra-ui/react';

import playMusic from '@/components/Common/FloatingMusic/utils/playMusic';
import ScrollToDown from '@/components/Common/ScrollToDown/V2';

import { BG_PRIMARY } from '@/constants/colors';
import { BG_COVER } from '@/constants/assets';
import { BOY_NAME_SHORT, GIRL_NAME_SHORT, IS_BOY_FIRST } from '@constants/index';
import useInvitation from '@hooks/useInvitation';

/**
 * Function to render Component CoverV3
 * This component will be dissapear after user click show `Undangan`
 * @return {JSX.Element}
 */
function Cover({ loaded, onSeeDetail }) {
  const [loading, setLoading] = useState(false);
  const isInvitation = useInvitation();

  const handleClickDetail = () => {
    if (loading) return undefined;

    playMusic();

    setLoading(true);
    setTimeout(() => {
      onSeeDetail();
      setLoading(false);
    }, 1500);
  };

  return (
    <Box minHeight="100vh" backgroundColor={BG_PRIMARY}>
      <Box
        height="100vh"
        bgSize="cover"
        bgPosition="top"
        bgImage={`url(${BG_COVER})`}
        color="white"
        marginBottom="-170px"
      >
        <Box width="100%" bgColor="blackAlpha.300" height="100%" padding="2rem">
          <Heading
            marginTop="72px"
            textColor="secondaryColorText"
            fontWeight="bold"
            fontSize="lg"
            textAlign="center"
            letterSpacing="2px"
            marginBottom="8px"
          >
            11.11.23
          </Heading>
          <Heading
            textColor="secondaryColorText"
            fontWeight="bold"
            fontSize="lg"
            textAlign="center"
            letterSpacing="2px"
            marginBottom="8px"
          >
            {isInvitation ? 'THE WEDDING OF' : 'WEDDING ANNOUNCEMENT'}
          </Heading>
          <Box
            textAlign="center"
            textColor="secondaryColorText"
            textTransform="uppercase"
            letterSpacing="4px"
          >
            <Heading fontWeight="bold" size="2xl">
              {IS_BOY_FIRST ? BOY_NAME_SHORT : GIRL_NAME_SHORT} &
            </Heading>
            <Heading fontWeight="bold" size="2xl" marginTop="-18px">
              {IS_BOY_FIRST ? GIRL_NAME_SHORT : BOY_NAME_SHORT}
            </Heading>
          </Box>
        </Box>
      </Box>
      <Box transform="translateY(-5px)">
        <Center>
          <ScrollToDown
            loaded={loaded}
            loading={loading}
            marginTop="16px"
            onClick={handleClickDetail}
          />
        </Center>
      </Box>
    </Box>
  );
}

Cover.propTypes = {
  loaded: bool.isRequired,
  onSeeDetail: func.isRequired,
};

export default Cover;
