const img = '';

export default [
  {
    img: img,
    title: '9 APRIL 2016',
    description: `We first met at Rima's birthday lunch (She's our only mutual at that time), thanks Rim! <br /> Seribu Rasa Menteng, Jakarta`,
  },
  {
    img: img,
    title: '25 MAY 2016',
    description: `Farewell dinner as Clarissa decided to continue her full time job in UAE.  <br />Pizza Ebira, Jakarta`,
  },
  {
    img: img,
    title: '20 AUGUST 2016',
    description: `Hendry flew 6,780km from Jakarta to Dubai
    to confess his feeling. <br />
    Officially dating  and starting our LDR. <br />
    Dubai International Airport, Dubai
    `,
  },
  {
    img: img,
    title: '3 FEBRUARY 2017',
    description: `After countless Skypes (no Zoom yet) and "aku tidur dulu ya"
    Clarissa came back home for good to Jakarta. <br />
    We travelled together and went on many wondrous adventures since then.
     `,
  },
  {
    img: img,
    title: '20 AUGUST 2021',
    description: `The Proposal  <br />
    On our 5th anniversary dinner, Hendry got down on one knee
    and popped the question "Will you marry me?"  <br />
    Karma Kandara, Bali 
     `,
  },
];
