import { useLocation } from '@reach/router';
import getQueryValue from '@invitato/helpers/dist/getQueryStringValue';

/**
 * Custom hook to return GuestName
 * @return {string}
 */
function usePartner() {
  const location = useLocation();
  const partner = Number(getQueryValue(location, 'partner'));

  const finalPartner = partner;

  return finalPartner;
}

export default usePartner;
