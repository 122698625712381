import React from 'react';
import { string } from 'prop-types';
import { Box, Text, Image, Heading, Center } from '@chakra-ui/react';

import WithAnimation from '@/components/Common/WithAnimation';
import { HEADING_STYLES } from '@constants/colors';
import InstagramButton from '@components/Common/InstagramButton';

function DetailCard({ fullName, imgUrl, instagramId, description, instagramIdProps }) {
  return (
    <Box color="mainColorText" textAlign="center" padding="24px 0">
      <WithAnimation>
        <Image
          src={imgUrl}
          alt=""
          width="100%"
          maxHeight="350px"
          objectFit="cover"
          objectPosition="center"
        />
      </WithAnimation>
      <Box padding="0 14px">
        <Center>
          <WithAnimation>
            <Heading
              marginTop="16px"
              {...HEADING_STYLES}
              textAlign="center"
              paddingLeft="0"
              dangerouslySetInnerHTML={{ __html: fullName }}
            />
          </WithAnimation>
        </Center>
        <WithAnimation>
          <Text dangerouslySetInnerHTML={{ __html: description }} margin="8px 0 24px 0" />
        </WithAnimation>
        {instagramId && (
          <WithAnimation>
            <InstagramButton
              id={instagramId}
              text={instagramId}
              backgroundColor="bgAlternative"
              color="mainColorTextLight"
              fontSize="md"
              onlyIcon={false}
              //fontFamily="body"
              {...instagramIdProps}
            />
          </WithAnimation>
        )}
      </Box>
    </Box>
  );
}

DetailCard.propTypes = {
  fullName: string.isRequired,
  imgUrl: string.isRequired,
  instagramId: string.isRequired,
  description: string.isRequired,
  instagramIdProps: string.isRequired,
};

export default React.memo(DetailCard);
